<template>
    <div class="col-right">
        <a-spin :spinning="spinning">

            <a-card>
                <h4 slot="title">企业档案</h4>
                <div class="row-profile ng-star-inserted">
                    <div class="col-logo ng-star-inserted">

                        <a-avatar shape="square" :size="64"
                                  :src="companyInfo.enterpriseLogo"/>
                    </div>
                    <div class="col-name">
                        <dl>
                            <dt>{{ companyInfo.enterpriseName }}</dt>
                            <!--                        <dd>StarID：PQVWOQR6</dd>-->
                        </dl>
                    </div>
                    <div
                            class="col-btn ng-star-inserted">
                        <a-button @click="() => {showUpdateProfileModal = true}">修改</a-button>
                    </div>
                </div>
            </a-card>

            <a-card>
                <div class="row-info ng-star-inserted">
                    <ul>
                        <li>
                            <div class="item-left">公司名称：</div>
                            <div class="item-right">{{ companyInfo.enterpriseName }}</div>
                        </li>
                        <li>
                            <div class="item-left">公司简称：</div>
                            <div class="item-right">{{companyInfo.enterpriseNameAbbreviation}}</div>
                        </li>
                        <li>
                            <div class="item-left">公司代码：</div>
                            <div class="item-right"> {{ companyInfo.unifiedSocialCreditCode }}
                                <a-icon type="question-circle"/>
                            </div>
                        </li>
                        <li>
                            <div class="item-left">公司法人：</div>
                            <div class="item-right">{{ companyInfo.legalPerson }}</div>
                        </li>
                        <li>
                            <div class="item-left">注册所在地：</div>
                            <div class="item-right">{{ companyInfo.enterpriseAddressStr }}</div>
                        </li>
                        <li>
                            <div class="item-left">注册详细地址：</div>
                            <div class="item-right">{{ companyInfo.enterpriseAddress }}</div>
                        </li>
                        <li>
                            <div class="item-left">营业期限：</div>
                            <div class="item-right"> {{ companyInfo.operatingStartTime }} 至
                                {{companyInfo.operatingLongTime ? '长期' : companyInfo.operatingEndTime}}
                            </div>
                        </li>
                        <li>
                            <div class="item-left">公司电话：</div>
                            <div class="item-right">{{companyInfo.enterpriseTelephone}}</div>
                        </li>
                        <li>
                            <div class="item-left">公司邮箱：</div>
                            <div class="item-right">{{companyInfo.enterpriseEmail}}</div>
                        </li>
                        <li>
                            <div class="item-left">联系人：</div>
                            <div class="item-right">{{ companyInfo.contactsName }}</div>
                        </li>
                        <li>
                            <div class="item-left">联系人手机号：</div>
                            <div class="item-right">{{ companyInfo.contactsPhone }}</div>
                        </li>
                        <li>
                            <div class="item-left">营业执照：</div>
                            <div class="item-right"><img alt="" class="item-img"
                                                         :src="companyInfo.enterpriseQualificationCertificate"></div>
                        </li>
                    </ul>
                </div>
            </a-card>
        </a-spin>
        <a-modal
                :dialog-style="{ top: '100px' }"
                width="700px"
                :visible="showUpdateProfileModal"
                :footer="false"
                @cancel="() => {showUpdateProfileModal = false}"
                title="修改企业信息"
        >
            <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" :form="form" @submit="goUpdateProfile">
                <a-form-item label="企业logo" required>
                    <div slot="extra">建议50*50px，支持jpg、jpeg、png格式，小于1M</div>
                    <div class="form-control-upload">
                        <div class="upload-wrap">
                            <div class="upload-left">
                                <a-upload
                                        name="file"
                                        list-type="picture-card"
                                        class="avatar-uploader"
                                        :show-upload-list="false"
                                        :action="uploadUrl"
                                        @change="changeUpload"

                                >
                                    <img v-if="companyInfo.enterpriseLogo" style="width: 100%; height:100%;"
                                         :src="companyInfo.enterpriseLogo" alt="avatar"/>
                                    <div v-else>
                                        <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
                                        <div class="ant-upload-text">
                                            点击上传
                                        </div>
                                    </div>
                                </a-upload>
                            </div>
                            <div class="upload-right">
                            </div>
                        </div>
                    </div>
                </a-form-item>
                <a-form-item label="公司简称">
                    <a-input
                            v-decorator="[
                            'enterpriseNameAbbreviation',
                            {
                                initialValue:companyInfo.enterpriseNameAbbreviation,
                                rules: [
                                {
                                    required: true,
                                    message: '请输入公司简称'
                                }
                            ]}
                        ]"
                    />
                </a-form-item>
                <a-form-item label="公司电话">
                    <a-input
                            v-decorator="[
                            'enterpriseTelephone',
                            {
                                initialValue:companyInfo.enterpriseTelephone,
                                rules: [
                                {
                                    message: '请输入公司电话'
                                }
                            ]}
                        ]"
                    />
                </a-form-item>
                <a-form-item label="公司邮箱">
                    <a-input
                            v-decorator="[
                            'enterpriseEmail',
                            {
                                initialValue:companyInfo.enterpriseEmail,
                                rules: [
                                {
                                    type:'email',
                                    message: '请输入邮箱地址'
                                }
                            ]}
                        ]"
                    />
                </a-form-item>
                <a-form-item label="联系人">
                    <a-input
                            v-decorator="[
                            'contactsName',
                            {
                                initialValue:companyInfo.contactsName,
                                rules: [
                                {
                                    required: true,
                                    message: '请输入联系人'
                                }
                            ]}
                        ]"
                    />
                </a-form-item>
                <a-form-item label="联系人手机号">
                    <a-input
                            v-decorator="[
                            'contactsPhone',
                            {
                                initialValue:companyInfo.contactsPhone,
                                rules: [
                                {
                                    required: true,
                                    message: '请输入手机号码'
                                }
                            ]}
                        ]"
                    />
                </a-form-item>
                <div class="recharge-btn">
                    <a-button @click="() => {showUpdateProfileModal=false;}">取消</a-button>
                    <a-button type="primary" style="margin-left: 8px" html-type="submit">保存</a-button>
                </div>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
    import service from "../../../utils/request";

    export default {
        name: "setting-profile",
        data() {
            return {
                spinning: false,
                showUpdateProfileModal: false,
                companyInfo: {},
                uploadLoading: false,
                uploadUrl: '',
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, {name: 'form'});
        },
        created() {
            this.getCompany();
            this.uploadUrl = this.$apiBaseURL + '/api/index/uploadImg'
            this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        },
        methods: {
            getCompany() {
                this.spinning = true;
                service.post(service.uri.company.getInfo).then(res => {
                    if (res.code == 200) {
                        this.spinning = false;
                        this.companyInfo = res.data;
                    }
                })
            },
            goUpdateProfile(e) {
                e.preventDefault();
                this.form.validateFieldsAndScroll((err, values) => {
                    console.log(values)
                    if (!err) {
                        console.log('Received values of form: ', values);
                        values.enterpriseLogo = this.companyInfo.enterpriseLogo;
                        service.post(service.uri.company.updateInfo, values).then(res => {
                            if(res.code == 200){
                                this.showUpdateProfileModal = false;
                                this.getCompany();
                            }
                        });
                    }
                });
            },
            changeUpload(e) {
                if (e.file.status === 'done') {
                    // Get this url from response in real world.
                    let res = e.file.response;
                    console.log(res)
                    if (res.code == 1) {
                        this.companyInfo.enterpriseLogo = res.data.url;
                    }
                }
            },
        }
    }
</script>

<style lang="less" scoped>
    .row-profile {
        padding-bottom: 0;
        border-bottom: 0 solid #ededed;
        display: flex;
        align-items: center;

        .col-logo {
            width: 136px;
            height: 140px;
            background-color: #e51f04;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .col-name {
            padding: 0 26px;
            flex: 1;
        }
    }

    .row-info {
        padding: 30px 0;

        li {
            display: flex;
            font-size: 12px;
            line-height: 20px;
            padding: 6px 0;

            .item-left {
                padding-right: 20px;
                text-align: right;
                width: 280px;
            }

            .item-right {
                flex: 1;
            }

            .item-img {
                max-width: 300px;
            }
        }
    }
</style>